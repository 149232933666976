const daChat = {
    "Allow access to camera": "Tillad adgang til kamera",
    "Allow access to microphone": "Tillad adgang til mikrofon",
    "An error has occurred during recording":
        "Der opstod en fejl under optagelsen",
    "An error has occurred during the recording processing":
        "Der opstod en fejl under behandling af optagelsen",
    "Attach files": "Vedhæft filer",
    Cancel: "Annuller",
    "Cannot seek in the recording": "Kan ikke søge i optagelsen",
    "Channel Missing": "Kanal mangler",
    Close: "Luk",
    "Close emoji picker": "Luk emoji-vælger",
    "Commands matching": "Kommandoer matcher",
    "Connection failure, reconnecting now...":
        "Forbindelsesfejl, forsøger at genoprette forbindelse...",
    Delete: "Slet",
    Delivered: "Leveret",
    "Download attachment {{ name }}": "Download vedhæftning {{ name }}",
    "Drag your files here": "Træk dine filer her",
    "Drag your files here to add to your post":
        "Træk dine filer her for at tilføje dem til dit opslag",
    "Edit Message": "Rediger besked",
    "Edit message request failed": "Redigeringsanmodning mislykkedes",
    Edited: "Redigeret",
    "Emoji matching": "Emoji matcher",
    "Empty message...": "Tom besked...",
    "Error adding flag": "Fejl ved tilføjelse af flag",
    "Error connecting to chat, refresh the page to try again.":
        "Fejl ved forbindelse til chat, opdater siden for at prøve igen.",
    "Error deleting message": "Fejl ved sletning af besked",
    "Error fetching reactions": "Fejl ved indlæsning af reaktioner",
    "Error marking message unread. Cannot mark unread messages older than the newest 100 channel messages.":
        "Fejl ved markering af besked som ulæst. Kan ikke markere ældre beskeder som ulæste end de nyeste 100 kanaler.",
    "Error muting a user ...": "Fejl ved dæmpning af en bruger ...",
    "Error pinning message": "Fejl ved fastgørelse af besked",
    "Error removing message pin": "Fejl ved fjernelse af fastgjort besked",
    "Error reproducing the recording": "Fejl ved gengivelse af optagelsen",
    "Error starting recording": "Fejl ved start af optagelse",
    "Error unmuting a user ...":
        "Fejl ved fjernelse af dæmpning på en bruger ...",
    "Error uploading attachment": "Fejl ved upload af vedhæftning",
    "Error uploading file": "Fejl ved upload af fil",
    "Error uploading image": "Fejl ved upload af billede",
    "Error · Unsent": "Fejl · Ikke sendt",
    "Error: {{ errorMessage }}": "Fejl: {{ errorMessage }}",
    "Failed to jump to the first unread message":
        "Kunne ikke springe til den første ulæste besked",
    "Failed to mark channel as read": "Kunne ikke markere kanal som læst",
    "Failed to play the recording": "Kunne ikke afspille optagelsen",
    "File is too large: {{ size }}, maximum upload size is {{ limit }}":
        "Filen er for stor: {{ size }}, maksimal uploadstørrelse er {{ limit }}",
    Flag: "Flag",
    "Latest Messages": "Seneste beskeder",
    "Mark as unread": "Marker som ulæst",
    Menu: "Menu",
    "Message Failed · Click to try again":
        "Besked mislykkedes · Klik for at prøve igen",
    "Message Failed · Unauthorized": "Besked mislykkedes · Uautoriseret",
    "Message deleted": "Besked slettet",
    "Message has been successfully flagged":
        "Besked er blevet markeret med succes",
    "Message pinned": "Besked fastgjort",
    "Messages have been marked unread.": "Beskeder er markeret som ulæste.",
    "Missing permissions to upload the attachment":
        "Manglende tilladelser til at uploade vedhæftningen",
    Mute: "Dæmp",
    New: "Ny",
    "New Messages!": "Nye beskeder!",
    "No chats here yet…": "Ingen chats her endnu...",
    "No results found": "Ingen resultater fundet",
    "Nothing yet...": "Intet endnu...",
    Ok: "Ok",
    "Open emoji picker": "Åbn emoji-vælger",
    "People matching": "Personer matcher",
    Pin: "Fastgør",
    "Pinned by": "Fastgjort af",
    "Recording format is not supported and cannot be reproduced":
        "Optagelsesformatet understøttes ikke og kan ikke gengives",
    Reply: "Svar",
    "Reply to Message": "Svar på besked",
    Search: "Søg i samtaler",
    "Searching...": "Søger...",
    Send: "Send",
    "Send Anyway": "Send alligevel",
    "Send message request failed":
        "Anmodning om afsendelse af besked mislykkedes",
    "Sending...": "Sender...",
    Shuffle: "Bland",
    "Slow Mode ON": "Langsom tilstand aktiveret",
    "Some of the files will not be accepted":
        "Nogle af filerne vil ikke blive accepteret",
    "This message did not meet our content guidelines":
        "Denne besked overholdt ikke vores indholdsretningslinjer",
    "This message was deleted...": "Denne besked blev slettet...",
    Thread: "Tråd",
    "To start recording, allow the camera access in your browser":
        "For at starte optagelse, tillad adgang til kameraet i din browser",
    "To start recording, allow the microphone access in your browser":
        "For at starte optagelse, tillad adgang til mikrofonen i din browser",
    "Type your message": "Skriv din besked",
    Unmute: "Fjern dæmpning",
    Unpin: "Fjern fastgørelse",
    "Unread messages": "Ulæste beskeder",
    "Unsupported attachment": "Ikke-understøttet vedhæftning",
    'Upload type: "{{ type }}" is not allowed':
        'Uploadtype: "{{ type }}" er ikke tilladt',
    "User uploaded content": "Bruger uploadede indhold",
    "Voice message": "Talebesked",
    "Wait until all attachments have uploaded":
        "Vent indtil alle vedhæftninger er uploadet",
    "You have no channels currently": "Du har ingen kanaler i øjeblikket",
    "You've reached the maximum number of files":
        "Du har nået det maksimale antal filer",
    "aria/Attachment": "Vedhæftning",
    "aria/Cancel Reply": "Annuller svar",
    "aria/Cancel upload": "Annuller upload",
    "aria/Channel list": "Kanalliste",
    "aria/Channel search results": "Kanalsøgeresultater",
    "aria/Close thread": "Luk tråd",
    "aria/Download attachment": "Download vedhæftning",
    "aria/Emoji picker": "Emoji-vælger",
    "aria/File input": "Filinput",
    "aria/File upload": "Filupload",
    "aria/Image input": "Billedinput",
    "aria/Load More Channels": "Indlæs flere kanaler",
    "aria/Menu": "Menu",
    "aria/Message Options": "Beskedmuligheder",
    "aria/Open Message Actions Menu": "Åbn beskedhandlingsmenu",
    "aria/Open Reaction Selector": "Åbn reaktionsvælger",
    "aria/Open Thread": "Åbn tråd",
    "aria/Reaction list": "Reaktionsliste",
    "aria/Remove attachment": "Fjern vedhæftning",
    "aria/Retry upload": "Forsøg upload igen",
    "aria/Send": "Send",
    live: "live",
    replyCount_one: "1 svar",
    replyCount_other: "{{ count }} svar",
    searchResultsCount_one: "1 resultat",
    searchResultsCount_other: "{{ count }} resultater",
    "this content could not be displayed": "dette indhold kunne ikke vises",
    "timestamp/DateSeparator":
        "{{ timestamp | timestampFormatter(calendar: true) }}",
    "timestamp/MessageTimestamp":
        "{{ timestamp | timestampFormatter(calendar: true) }}",
    "timestamp/SystemMessage":
        "{{ timestamp | timestampFormatter(format: dddd L) }}",
    unreadMessagesSeparatorText_one: "1 ulæst besked",
    unreadMessagesSeparatorText_other: "{{count}} ulæste beskeder",
    "{{ commaSeparatedUsers }} and {{ moreCount }} more":
        "{{ commaSeparatedUsers }} og {{ moreCount }} flere",
    "{{ commaSeparatedUsers }}, and {{ lastUser }}":
        "{{ commaSeparatedUsers }}, og {{ lastUser }}",
    "{{ firstUser }} and {{ secondUser }}":
        "{{ firstUser }} og {{ secondUser }}",
    "{{ imageCount }} more": "{{ imageCount }} mere",
    "{{ memberCount }} members": "{{ memberCount }} medlemmer",
    "{{ user }} has been muted": "{{ user }} er blevet dæmpet",
    "{{ user }} has been unmuted": "{{ user }} er blevet fjernet fra dæmpning",
    "{{ user }} is typing...": "{{ user }} skriver...",
    "{{ users }} and more are typing...": "{{ users }} og flere skriver...",
    "{{ users }} and {{ user }} are typing...":
        "{{ users }} og {{ user }} skriver...",
    "{{ watcherCount }} online": "{{ watcherCount }} online",
    "{{count}} unread_one": "{{count}} ulæst",
    "{{count}} unread_other": "{{count}} ulæste",
    "🏙 Attachment...": "🏙 Vedhæftning...",
    "1 comment": "1 kommentar",
    "1 like": "1 like",
    "1 repost": "1 genindlæg",
    Activity: "Aktivitet",
    "Animals & Nature": "Dyr & Natur",
    "Choose your default skin tone": "Vælg din standard hudfarve",
    Clear: "Ryd",
    Custom: "Brugerdefineret",
    Details: "Detaljer",
    "Emoji categories": "Emoji kategorier",
    Flags: "Flag",
    "Food & Drink": "Mad & Drikke",
    "Frequently Used": "Hyppigt Brugte",
    "Getting website data...": "Henter websitedata...",
    "Load activities": "Indlæs aktiviteter",
    "Load more": "Indlæs mere",
    "New Post": "Ny Post",
    "No data to display...": "Ingen data at vise...",
    "No emoji found": "Ingen emoji fundet",
    Objects: "Objekter",
    "Pick your emoji": "Vælg din emoji",
    Post: "Slå op",
    "Search Results": "Søgeresultater",
    "Smileys & Emotion": "Smilies & Følelser",
    "Start Typing...": "Begynd at skrive...",
    Symbols: "Symboler",
    "Travel & Places": "Rejser & Steder",
    "Type your post...": "Skriv dit opslag...",
    "You have 1 new notification": "Du har 1 ny notifikation",
    "You have {{ notificationCount }} new notifications":
        "Du har {{ notificationCount }} nye notifikationer",
    "{{ actorName }} and 1 other commented on your {{ activityVerb }}":
        "{{ actorName }} og 1 anden kommenterede på din {{ activityVerb }}",
    "{{ actorName }} and 1 other followed you":
        "{{ actorName }} og 1 anden fulgte dig",
    "{{ actorName }} and 1 other liked your {{ activityVerb }}":
        "{{ actorName }} og 1 anden likede din {{ activityVerb }}",
    "{{ actorName }} and 1 other reposted your {{ activityVerb }}":
        "{{ actorName }} og 1 anden genindlagde din {{ activityVerb }}",
    "{{ actorName }} and {{ countOtherActors }} others commented on your {{ activityVerb }}":
        "{{ actorName }} og {{ countOtherActors }} andre kommenterede på din {{ activityVerb }}",
    "{{ actorName }} and {{ countOtherActors }} others followed you":
        "{{ actorName }} og {{ countOtherActors }} andre fulgte dig",
    "{{ actorName }} and {{ countOtherActors }} others liked your {{ activityVerb }}":
        "{{ actorName }} og {{ countOtherActors }} andre likede din {{ activityVerb }}",
    "{{ actorName }} and {{ countOtherActors }} others reposted your {{ activityVerb }}":
        "{{ actorName }} og {{ countOtherActors }} andre genindlagde din {{ activityVerb }}",
    "{{ actorName }} commented on your {{ activityVerb }}":
        "{{ actorName }} kommenterede på din {{ activityVerb }}",
    "{{ actorName }} followed you": "{{ actorName }} fulgte dig",
    "{{ actorName }} liked your {{ activityVerb }}":
        "{{ actorName }} likede din {{ activityVerb }}",
    "{{ actorName }} reposted your {{ activityVerb }}":
        "{{ actorName }} genindlagde din {{ activityVerb }}",
    "{{ countComments }} comments": "{{ countComments }} kommentarer",
    "{{ countLikes }} likes": "{{ countLikes }} likes",
    "{{ countReposts }} reposts": "{{ countReposts }} genindlæg",
};

export default daChat;
