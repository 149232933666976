import * as stream from "stream-chat-react";
const { Streami18n } = stream;
import { Streami18n as Streami18nFeed } from "react-activity-feed";
import da from "./locales/da.json";
import i18n from "i18next";
import daLocaleBase from "dayjs/locale/da";
import daChat from "./chatLocales/daChat";

/**
 * Builds up valid keypaths for translations.
 * Update to your default locale of choice if not English.
 */
type DefaultLocale = typeof da;
export type TxKeyPath = RecursiveKeyOf<DefaultLocale>;

type RecursiveKeyOf<TObj extends Record<string, any>> = {
    [TKey in keyof TObj & string]: TObj[TKey] extends Record<string, any>
        ? `${TKey}` | `${TKey}.${RecursiveKeyOf<TObj[TKey]>}`
        : `${TKey}`;
}[keyof TObj & string];

/**
 * Translates text.
 *
 * @param key The i18n key.
 */
export function translate(
    key: TxKeyPath,
    options?: Record<string, any>
): string {
    return key ? i18n.t(key, options) : "";
}

export const getStreamI18 = (type?: string) => {
    let streami18n = new Streami18n();

    if (type === "feed") {
        streami18n = new Streami18nFeed();
    }

    const language = i18n.language.split("-")[0];

    const daLocale = {
        ...daLocaleBase,
        calendar: {
            lastDay: "[I går kl] LT",
            sameDay: "[I dag kl] LT",
            nextDay: "[I morgen kl] LT",
            lastWeek: "[Sidste] dddd [kl] LT",
            nextWeek: "dddd [kl] LT",
            sameElse: "L",
        },
    };

    switch (language) {
        case "en":
        case "es":
        case "fr":
        case "he":
        case "hi":
        case "it":
        case "ja":
        case "ko":
        case "ru":
        case "tr":
            streami18n.setLanguage(language);
            break;
        case "da":
            streami18n.registerTranslation("da", daChat, daLocale);
            streami18n.setLanguage("da");
            break;
        default:
            break;
    }

    return streami18n;
};
